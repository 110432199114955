.menu-container{
    &.desktop .category-title, &.mobile .category-title{
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.desktop{
        .MuiAccordionSummary-content{
            a {
                .category-title{
                    padding: 0px;
                }
                max-width: 100%;
            }
        .MuiAccordionSummary-root{
            padding: 4px;
        }

            max-width: 100%;
        }
        margin-bottom: 10%;
    }
    &.mobile{
        .MuiAccordionSummary-content{
            a {
                max-width: 182px;
            }
        }
    }
}

.PDPVendorRowHilight.PDPVendorRowHilight {
    background-color: rgba(84, 231, 236, 0.3) !important;
}

.menu-country-flags   {
    min-width: 10rem !important;
}

.mro-debug {
    color: ivory;
}